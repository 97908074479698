<template>
    <div class="page">
        <to-history :prop-message="'我的联系人'"></to-history>
        <div id="top" class="search_wap">
            <a-input-search style="width:47%" v-model="searchText" class="sInput" placeholder="根据联系人|客户|跟进人搜索"
                @search="onSearch" />
            <a-input-search style="width:47%" v-model="mobile" class="sInput" placeholder="根据联系人手机号搜索"
                @search="onSearch" />



        </div>


        <div v-if="this.pagination.total" style="padding-left: 20px;height: 2em;background: rgba(255, 255, 255, .2);line-height: 2em;color: #fff;">
            <img style="height: 100%;" src="@/assets/imgs/wap/ata.png" alt="">
            当前数据条数{{ this.pagination.total }}条
        </div>
        <!--  style="height: calc(100vh - 140px);overflow: scroll;" -->
        <div v-infinite-scroll="getList" infinite-scroll-disabled="dataLoadStop" infinite-scroll-distance="50">
            <div class="lists" v-for="(item, index) in data" :key="index">
                <div class="list_row_heraer">
                    <img :src="ajaxUrl + '/scrm/index/getFile?id=' + item.tmjcpb_linkmans__zd1634692679" alt="">
                    <div>
                        <div>{{ item.tmjcpb_linkman__name }}
                            <img :class="item.tmjcpb_linkmans__zd1634692668 == '男' ? '' : 'girl'"
                                src="@/assets/imgs/wap/sex.png" alt="">
                        </div>
                        <div>
                            <img src="@/assets/imgs/wap/mobile.png" alt="">
                            {{ item.tmjcpb_linkman__mobile }}
                        </div>
                        <div>
                            <img src="@/assets/imgs/wap/position.png" alt="">
                            {{ item.tmjcpb_linkman__position }}
                        </div>
                    </div>
                </div>
                <div class="list_lable">qq <span>{{ item.tmjcpb_linkmans__zd1634693935 }}</span></div>
                <div class="list_lable">微信 <span>{{ item.tmjcpb_linkmans__zd1634693942 }}</span></div>
                <div class="list_lable">公司 <span>{{ item.tmjcpb_linkmans__zd1634694043 }}</span></div>
                <div class="list_lable">行业 <span>{{ item.tmjcpb_linkmans__zd1634694050 }}</span></div>
                <div class="list_lable">推荐人 <span>{{ item.tmjcpb_linkmans__zd1634694062 }}</span></div>
                <div class="list_lable">住址 <span>{{ item.tmjcpb_linkmans__zd1634694097 }}</span></div>
                <div class="list_lable">公司地址 <span>{{ item.tmjcpb_linkmans__zd1634694115 }}</span></div>
                <!-- <div>跟进人 <span>{{ item.tmjcpb_linkman__position }}</span></div>
                <div>客户 <span>{{ item.tmjcpb_linkman__position }}</span></div> -->
                <!-- <div class="list_row" v-for="(value, name) in item" :key="name">
                    <div v-if="name!='tmjcpb_linkman_lid'"><b>{{name | dataFiltter}}</b> {{value}}</div>
                </div> -->
                <!-- <div class="btn_con">
                    <a-checkbox @change="choose($event,index)" style="margin-right:10px"></a-checkbox>
                    <a-button type="primary" size="small" ghost @click="showDetail(item.tmjcpb_corp__ids,item.tmjcpb_corp__name,true)">编辑</a-button>
                </div> -->
            </div>
            <!-- <div class="list-load-end" style="text-align:center;padding-top:25px;padding-bottom:50px">
                <span>{{dataLoadNomore ? "--没有更多数据了--" : "加载中..."}}</span>
            </div> -->

            <a-pagination v-if="pagination.total" style="text-align: center;" @change="onChange"
                v-model="pagination.current" :total="pagination.total" />
            <div v-if="pagination.total == 0" class="list-load-end"
                style="text-align:center;padding-top:25px;padding-bottom:50px">
                <span>--暂无数据--</span>
            </div>
        </div>
        <div style="height: 100px;"></div>
        <!-- <div class="tMenu flex">
            <div class="flex" @click="showModal">
                <img src="@/assets/imgs/tMenu1.png" alt=""><label for="">打标签</label>
            </div>
            <div class="flex" @click="showAssign">
                <img src="@/assets/imgs/tMenu2.png" alt=""><label for="">指派跟进人</label>
            </div>
            <div class="flex" @click="addClient">
                <img src="@/assets/imgs/tMenu3.png" alt=""><label for="">新建</label>
            </div>
        </div> -->
        <!-- 打标签弹框 -->
        <a-modal v-model="visible" title="打标签" ok-text="确认" cancel-text="取消" @ok="hideModal" @cancel="cancelTag">
            <div v-for="(tags, index) in allTags" :key="index">
                <label style="font-size:18px;">{{ tags.name }}</label>
                <div style="font-size:16px;margin:10px 0;">
                    <template v-for="(tag, index2) in tags.options">
                        <a-checkable-tag :key="index2" :checked="tag.isChecked == true"
                            @change="handleChange(index, index2)">
                            {{ tag.value }}
                        </a-checkable-tag>
                    </template>
                </div>
            </div>
        </a-modal>
        <!-- <a-modal v-model="assignVisible" title="分配客户" ok-text="确认" cancel-text="取消" @ok="assignHideModal">
            <label class="info" for="">跟进人</label>
            <a-select style="width: 80%;margin: 20px 10px" size="large" v-model="staff_uid" placeholder="请选择跟进人">
                <a-select-option v-for="(item,index) in handlerList" :key="index" :value="item.userid">
                    {{ item.name }}
                </a-select-option>
            </a-select>
        </a-modal> -->
        <!-- 表格设置 -->
        <table-set :setFlag="setFlag" ref="setTable" @updateFlag="updateFlag" @updateSet="updateSet"></table-set>
        <!-- 新建客户 -->
        <!-- <add-client ref="addFunc" :addFlag="addFlag"  @updateAddFlag="updateAddFlag" @updateClientList="updateClientList"></add-client> -->
        <!-- 客户详情 -->
        <!-- <client-detail :detailFlag="detailFlag" :clientId="clientId"  @updateDetailFlag="updateDetailFlag" @updateAddFlag2="updateAddFlag2" @clientIdFunc="clientIdFunc" @linkmanIdFunc="linkmanIdFunc"></client-detail> -->
        <!-- 筛选功能 -->
        <!-- 选择员工 -->
        <staff :staffFlag="staffFlag" @updateStaffFlag="updateStaffFlag"></staff>
        <!-- 筛选 -->
        <screen ref="edit" :screenFlag="screenFlag" @updateScreenFlag="updateScreenFlag" @updateScreen="updateScreen">
        </screen>
    </div>
</template>
<script>
// import $ from "jquery";
import tableSet from '../../../components/tableSet.vue';
// import AddClient from '../../../components/addClient.vue';
// import ClientDetail from '../../../components/clientDetail.vue';
import Screen from '../../../components/screen.vue';
import Staff from '../../../components/staff.vue';
import { requestXml, getDateTime, ajaxUrl } from '../../../assets/js/request';
import infiniteScroll from "vue-infinite-scroll";
let all = [{ name: "corp__name", show: false, text: "企业名称", type: "text" }];
let tagForm = {};
export default {
    components: { tableSet, Screen, Staff },
    directives: { infiniteScroll },
    data() {
        return {
            // 打标签
            ajaxUrl: ajaxUrl,
            ids: [],
            visible: false,
            allTags: ['Movies', 'Books', 'Music', 'Sports'],
            selectedTags: [],
            // 分配客户
            // assignVisible: false,
            // staff_uid: "",
            // handlerList: [],
            staffFlag: false,

            // 搜索的内容
            searchText: "",
            mobile: "",
            // 保存的筛选列表
            screenList: [],
            screen: "",
            // 表格设置展示与否
            setFlag: false,
            // 添加客户展示与否
            // addFlag: false,
            // 客户详情展示与否
            // detailFlag: false,
            // 客户id
            clientId: "",
            // 筛选功能展示与否
            screenFlag: false,
            all,
            data: [],
            // 表格字段显示
            columns: [
                { title: '姓名', dataIndex: 'name', scopedSlots: { customRender: 'id' } },
                { title: '公司名称', dataIndex: 'company' },
                { title: '标签', dataIndex: 'tags' },
                { title: '会员等级', dataIndex: 'vipLevel' }
            ],

            selectedRowKeys: [],

            // 分页
            dataLoadStop: false,  //列表数据加载停止状态
            dataLoadNomore: false,    //列表数据加载是否完成
            pagination: {
                current: 1,
                total: 100,
                pageSize: 10, // 默认每页显示数量
                showSizeChanger: true, // 显示可改变每页数量
                pageSizeOptions: ['10', '20', '50', '100'], // 每页数量选项
                showQuickJumper: true,
                // showTotal: total => `Total ${total} items`, // 显示总数
                onChange: (current) => this.pagesChange(current), //点击当前页码
                onShowSizeChange: (current, pageSize) => {
                    //设置每页显示数据条数，current表示当前页码，pageSize表示每页展示数据条数
                    console.log(pageSize);
                    this.showSizeChange(current, pageSize);
                },
            },
        };
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.fromPath = from.path
        })
    },
    beforeDestroy() {
        sessionStorage.setItem('curpage', JSON.stringify({ "key": this.searchText, "mobile": this.mobile, 'page': this.pagination }))
    },
    mounted: function () {

        if (this.fromPath == "/scrm_wap/order/orderAdd" || this.fromPath == '/scrm_wap/order/orderDetail') {
            var data = JSON.parse(sessionStorage.getItem('curpage'))
            sessionStorage.removeItem('curpage')
            this.pagination = data.page
            this.searchText = data.key
            this.mobile = data.mobile
        }
        this.getScreenList(1);
        this.getAll();
        this.getList();
    },
    methods: {

        onChange() {
            window.location.href = '#top'
            this.getList();
        },
        // 客户选择
        choose(e, index) {
            if (e.target.checked) {
                this.ids.push(this.data[index].tmjcpb_corp__ids)
            } else {
                let delIndex = this.ids.findIndex(item => item == this.data[index].tmjcpb_corp__ids);
                this.ids.splice(delIndex, 1);
            }
        },

        // 打标签的框
        showModal() {
            if (this.ids.length == 0) {
                this.$message.info("请选择客户");
                return false;
            } else {
                this.visible = true;
                requestXml("/scrm/corp/getTagOptions", "POST", (res) => {
                    for (let i = 0; i < res.length; i++) {
                        for (let j = 0; j < res[i].options.length; j++) {
                            res[i].options[j].isChecked = false;
                        }
                    }
                    this.allTags = res
                })
            }
        },
        hideModal() {
            this.pagination.current -= 1;
            this.visible = false;
            // 创建一个空对象存放要提交的数据，每个标签的名字做为key 将每个标签下边选中的值做为value
            for (let i = 0; i < this.allTags.length; i++) {
                let arr = [];
                for (let j = 0; j < this.allTags[i].options.length; j++) {
                    if (this.allTags[i].options[j].isChecked == true) {
                        arr.push(this.allTags[i].options[j].value);

                        tagForm[this.allTags[i].field] = arr.join(",");
                    }
                }
            }
            requestXml("/scrm/corp/setTag", "POST", (res) => {
                this.selectedRowKeys = [];
                this.ids = [];

                if (res == "success") {
                    tagForm = {};
                    this.data = [];
                    this.getList();
                }
            }, { ...tagForm, "ids": this.ids })

        },
        cancelTag() {
            tagForm = {};
        },
        handleChange(idx, idx2) {
            let tag = this.allTags[idx].options[idx2].isChecked;
            this.allTags[idx].options[idx2].isChecked = !tag;
        },

        // 分配客户
        showAssign() {
            if (this.ids.length == 0) {
                this.$message.info("请选择客户");
                return false;
            } else {
                this.staffFlag = true;
            }
        },
        // 分配完 取消表格选中 清空客户 重新加载列表
        updateStaffFlag(data) {
            this.pagination.current -= 1;
            if (data != 1) {
                requestXml("/scrm/corp/setStaff", "POST", (res) => {
                    if (res == "success") {
                        this.ids = [];
                        this.data = [];
                        this.getList();
                    }
                }, { "staff_uid": data.userid, "ids": this.ids })
            }
            this.staffFlag = false;
        },

        // 获取客户字段
        getAll() {
            requestXml("/scrm/Linkman/getField", "GET", (res) => {
                all = res;
                var arr = [
                    {
                        iswrite: 1, name: "tmjcpb_corp__name", options: "", text: "客户", type: "SingleText", value: ""
                    }, {
                        iswrite: 1, name: "tmjcpb_staff__name", options: "", text: "跟进人", type: "SingleText", value: ""
                    }]
                all = all.slice(0, 2).concat(arr).concat(all.slice(2, all.length));
                console.log(all)
            })
        },
        // 获取客户列表
        getList() {
            this.dataLoadStop = true;
            requestXml("/scrm/Linkman/getList", "POST", (res) => {
                for (let i = 0; i < res.list.length; i++) {
                    // 列表中类型为时间的处理为年月日格式
                    for (let j = 0; j < all.length; j++) {
                        if (all[j].type == "date") {
                            res.list[i][all[j].name] = getDateTime(res.list[i][all[j].name]);
                        } else if (all[j].type == "dateTime") {
                            res.list[i][all[j].name] = getDateTime(res.list[i][all[j].name], 3);
                        }
                    }
                }
                console.log(res.list)
                this.data = res.list
                // this.data = this.data.concat(res.list);
                // if(res.page.maxpage>this.pagination.current){
                //     this.pagination.current += 1
                //     this.dataLoadStop = false
                // }else{
                //     this.dataLoadNomore = true
                // }
                this.pagination.total = Number(res.page.total);
                this.pagination.pageSize = Number(res.page.pagesize);
            }, { "key": this.searchText, "mobile": this.mobile, "page": { "curpage": this.pagination.current, "pagesize": this.pagination.pageSize } })
        },
        // 搜索
        onSearch() {
            // if(this.searchText != ""){
            this.data = [];
            this.dataLoadNomore = false;
            this.pagination.current = 1;
            this.getList();
            // }
        },


        // 获取保存的筛选列表
        getScreenList(type) {
            requestXml("/scrm/corp/getFilterList", "POST", (res) => {
                this.screenList = res;
                if (type == 1) {
                    this.screen = "全部";
                }
            })
        },
        // 筛选下拉框
        screenChange() {
            this.screenFlag = false;
            this.data = [];
            this.getList();
        },
        // 查询筛选func
        getScreen() {
            requestXml("/scrm/corp/getFilter", "POST", (res) => {
                for (var i = 0; i < res.length; i++) {
                    if (res[i].checked) {
                        res[i].isChecked = true;
                    }
                }
                this.$refs.edit.data = res;
            }, { id: this.screen })
        },
        // 编辑筛选
        // editSCreen(id,name){
        //     this.$refs.sel.blur();
        //     this.screenFlag = true;
        //     this.screen = id;
        //     this.$refs.edit.name = name;
        //     this.getScreen();
        // },
        // 删除筛选
        // delSCreen(id){ 
        //     requestXml("/scrm/corp/delFilter","POST",(res) => {
        //         if(res == "success"){
        //             this.getScreenList();
        //         }
        //     },{id: id})
        // },
        // 筛选子组件
        showScreen: function () {
            this.$refs.sel.blur();
            this.screenFlag = true;
            this.$refs.edit.getScreenList();
        },
        // 子组件传值给父组件
        updateScreenFlag(data) {
            this.screenFlag = data // 改变了父组件的值
        },
        // 接收到添加的筛选 用返回的筛选id请求客户列表
        updateScreen(data) {
            // console.log("data",data)
            this.getScreenList();
            this.screen = data.id;
            this.data = [];
            this.getList();
        },

        // 表格设置
        showSet: function () {
            this.setFlag = true;
            this.$refs.setTable.getTable();
        },
        // 子组件传值给父组件
        updateFlag(data) {
            this.setFlag = data // 改变了父组件的值
        },
        // 接收到表格设置的数据后，设置table中的字段显示与否
        updateSet(data) {
            this.columns = data;
            this.data = [];
            this.pagination.current = 1;
            this.getList();
        },

        // 添加客户
        addClient() {
            this.$router.push({
                path: "/scrm_wap/client/addClient"
            });
        },

        // // 添加客户
        // showAdd: function(){
        //     this.addFlag = true;
        //     this.$refs.addFunc.addClient();
        // },
        // // 子组件传值给父组件
        // updateAddFlag (data) {
        //     this.addFlag = data // 改变了父组件的值
        // },
        // updateClientList() {
        //     this.getList();
        // },

        // 添加联系人
        // 子组件传值给父组件
        updateAddFlag2(data) {
            this.addFlag2 = data // 改变了父组件的值
        },
        clientIdFunc(id) {
            //添加联系人传客户id
            this.$refs.addFunc2.id = id;
        },
        linkmanIdFunc(id) {
            //联系人详情 编辑联系人传联系人id
            this.$refs.addFunc2.getDetail(id);
        },

        // 客户详情
        showDetail: function (id, name, edit) {
            this.$router.push({
                path: "/scrm_wap/client/clientDetail",
                query: {
                    id: id,
                    clientName: name,
                    edit: edit
                }
            });
        },
        // 子组件传值给父组件
        updateDetailFlag(data) {
            this.detailFlag = data // 改变了父组件的值
        },
    },
    filters: {
        dataFiltter(val) {
            for (var i = 0; i < all.length; i++) {
                if (val == all[i].name) {
                    return all[i].text + "："
                }
            }
        }
    }
};
</script>
<style scoped>
.page {
    background-image: url('../../../assets/imgs/wap/bg.png');
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: 100% auto;
    padding-bottom: 50px;
}

/deep/.ant-input {
    background: #3056BF !important;
    color: #fff;
}

/deep/ .ant-input::placeholder,
/deep/ .ant-select::placeholder {
    color: #fff;
    font-size: 12px;
    /* 将占位符文本颜色设置为灰色 */
}
/deep/ .anticon svg,
/deep/ .ant-select svg {
    color: #fff;
}

body {
    background-color: #f5f5f5;
}

.search_wap {
    text-align: left;
    margin-bottom: 15px;
    height: 40px;
    line-height: 40px;
}

.search_wap .ant-input-search {
    width: 50vw;
    margin: 0 5px;
}

.search_wap .ant-select {
    width: 30vw;
}

.lists {
    width: 92%;
    /* height: 26rem; */
    padding: 3rem;
    margin: 15px auto;
    background-color: #fff;
    border-radius: 10px;
    color: #202020;
}

.lists .list_row div {
    /* float: left;
        width: 33.33%; */
    /* height: 6rem;*/
    line-height: 5rem;
    text-align: left;
}

.btn_con {
    text-align: left;
}

.btn_con button {
    color: #36A3FF;
    border-color: #36A3FF;
    margin: 1rem;
}

/* .ant-modal{
        width: 80%;
    } */
.tMenu {
    position: fixed;
    bottom: 3rem;
    width: calc(100% - 6rem);
    margin: 3rem 3rem 0;
    height: 50px;
    background: #fff;
    border-radius: 4px;
    color: #36A3FF;
    font-size: 1.6rem;
    justify-content: space-around;
}

.tMenu div {
    flex-direction: column;
    align-items: center;
}

.tMenu img {
    width: 18px;
    height: 18px;
    margin: 5px 0;
}

.ant-tag {
    margin: 4px 8px 4px 0;
    border: 1px solid #d9d9d9;
    padding: 3px 7px;
}
.list_row_heraer{
    display: flex;
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
}
.list_row_heraer img{
    width: 20px;
    vertical-align: middle;
}
.list_row_heraer>img{
    border-radius: 10px;
    width: 100px;
    height: 100px;
    margin-right: 10px;
}
.list_lable{
    color: #666666;
}
.list_lable span{
    color: #000000;
    float: right;
}
.girl{
    transform: rotate(90deg);
}
</style>
